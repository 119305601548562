export function openWindow(url, name, options) {
  const config = Object.assign({
    height: 400,
    width: 550,
    location: 'no',
    toolbar: 'no',
    status: 'no',
    directories: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'no',
    centerscreen: 'yes',
  }, options);

  window.open(
    url,
    name || '',
    Object.keys(config).map(key => `${key}=${config[key]}`).join(', '),
  );
}

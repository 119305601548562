import Modal from "./Modal";
import React from "react";
import AppContext from "../contexts/AppContext";

export default class MessageModal extends Modal {

  constructor(props) {
    super(props);
    this.className = ""; // todo
  }

  renderModal() {
    return <div className="modal-container">
      <div className="modal">
        {this.props.children}
        <button className="btn-upload-foto" children={"ok"} onClick={() => this.dismiss()} />
      </div>
    </div>
  }
}

MessageModal.contextType = AppContext;
